import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { Sidebar } from 'primereact/sidebar';
import { Error, Heading, Row } from '../../../styled/styled';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import HorizontalFieldValue from '../../Forms/HorizontalFieldValue';
import { InputText } from 'primereact/inputtext';
import { ApiContext } from '../../../../../api/api';
import useNavigation, { Page } from '../../../../../hooks/useNavigation';

interface ComponentProps {
  onHide: () => void;
  visible: boolean;
  onCompleted: (newUUID: string) => void;
}

const validationSchema = yup.object().shape({
  workspaceName: yup.string().required(),
});

interface FormData {
  workspaceName: string;
}

const initialData: FormData = {
  workspaceName: '',
};

const AddWorkspace: React.FC<ComponentProps> = ({ onHide, visible, onCompleted }) => {
  const { createWorkspace } = useContext(ApiContext);

  const formik = useFormik<FormData>({
    initialValues: initialData,
    validationSchema,
    onSubmit: (values) => save(values),
  });

  const save = (values: FormData) => {
    createWorkspace({ workspaceName: formik.values.workspaceName }, (d) => {
      onCompleted(d.uuid);
    });
  };

  useEffect(() => {
    if (visible) formik.resetForm();
  }, [visible]);

  return (
    <>
      <Sidebar
        onHide={onHide}
        visible={visible}
        position="right"
        baseZIndex={1000000}
        blockScroll={true}
        dismissable={false}
        style={{ minWidth: '25rem' }}
      >
        <Heading>Nový workspace</Heading>
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <HorizontalFieldValue
              label={'Nazev workspace'}
              value={
                <>
                  <InputText
                    style={{ width: '100%' }}
                    name={'workspaceName'}
                    value={formik.values.workspaceName}
                    onChange={formik.handleChange}
                  />
                  <Error>{formik.errors.workspaceName}</Error>
                </>
              }
            />
          </Row>

          <ButtonRow>
            <Button label={'Vytvorit'} type={'submit'} />
            <Button
              label={'Zavrit'}
              onClick={(e) => {
                e.preventDefault();
                onHide();
              }}
            />
          </ButtonRow>
        </form>
      </Sidebar>
    </>
  );
};

const ButtonRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

export default AddWorkspace;
