import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import useNavigation, { Page } from '../../../../hooks/useNavigation';
import getConfig from 'next/config';
import { ApiContext } from '../../../../api/api';
import { GetIdentityOutDTO, UserLoginInfoOutDTO } from '../../../../api/frontend-service.v1';
import { Dropdown } from 'primereact/dropdown';
import { dumpVars } from '../../../../lib/debug';
import AddWorkspace from './sidepanel/AddWorkspace';
import { UserContext } from '../../security/UserProvider';
import Link from 'next/link';
import Image from 'next/image';

interface ComponentProps {
  subMenu: any;
}

const TopPanel: React.FC<ComponentProps> = ({ subMenu = <></> }) => {
  const { getUserIdentity } = useContext(ApiContext);
  const { userInfoWithPermissions } = useContext(UserContext);

  const { publicRuntimeConfig } = getConfig();
  const { user, logout, isAuthenticated } = useAuth0();
  const { createNavigationLink, getWorkspaceUUID, hardNavigate, navigate, workspaceUUID } = useNavigation();
  const [identity, setIdentity] = useState<GetIdentityOutDTO>();
  const [openMenu, setOpenMenu] = useState(false);
  const reference = useRef(undefined);
  const [addWorkspace, setAddWorkspace] = useState(false);
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);

  const handleClickOutside = (event) => {
    if (
      (reference.current && !reference.current.contains(event.target)) ||
      event.target?.getAttribute('data-close-menu')
    ) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserIdentity((d) => {
        setIdentity(d);
      });
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, [isAuthenticated]);

  const switchWorkspace = (newWorkspaceUUID: string) => {
    hardNavigate(Page.DASHBOARD, { workspaceUUID: newWorkspaceUUID });
  };

  return (
    <>
      <MaxWidthWrapper>
        <TopPanelWrapper>
          <BurgerMenuWrapper onClick={(e) => setShowResponsiveMenu((s) => !s)}>M</BurgerMenuWrapper>
          <LogoWrapper>
            <a href={createNavigationLink(Page.DASHBOARD, { workspaceUUID: getWorkspaceUUID() })}>
              <Logo src="/images/svg/logo.svg" />
            </a>
            <Divider></Divider>
          </LogoWrapper>
          {isAuthenticated && (
            <>
              <ToolbarContainer>
                <Menu>
                  {userInfoWithPermissions.security.showExpenseModule && (
                    <MenuItem
                      href={createNavigationLink(Page.EXPENSE_LIST, {
                        applicationParams: {},
                        workspaceUUID: getWorkspaceUUID(),
                      })}
                    >
                      Naklady
                    </MenuItem>
                  )}

                  {userInfoWithPermissions.security.showInvoiceModule && (
                    <MenuItem
                      href={createNavigationLink(Page.INVOICE_LIST, {
                        applicationParams: {},
                        workspaceUUID: getWorkspaceUUID(),
                      })}
                    >
                      Faktury
                    </MenuItem>
                  )}

                  {userInfoWithPermissions.security.showBankModule && (
                    <MenuItem
                      href={createNavigationLink(Page.BANKITEMS_LIST, {
                        workspaceUUID: getWorkspaceUUID(),
                      })}
                    >
                      Bankovni pohyby
                    </MenuItem>
                  )}

                  {userInfoWithPermissions.security.showCars && (
                    <MenuItem
                      href={createNavigationLink(Page.CAR_LIST, {
                        workspaceUUID: getWorkspaceUUID(),
                      })}
                    >
                      Auta
                    </MenuItem>
                  )}

                  {userInfoWithPermissions.security.showRenting && (
                    <MenuItem
                      href={createNavigationLink(Page.RENTAL_LIST, {
                        workspaceUUID: getWorkspaceUUID(),
                      })}
                    >
                      Pronajmy
                    </MenuItem>
                  )}
                  {userInfoWithPermissions.security.showRoomservice && (
                    <MenuItem
                      href={createNavigationLink(Page.ROOMSERVICE_OVERVIEW, {
                        workspaceUUID: getWorkspaceUUID(),
                      })}
                    >
                      Uklid
                    </MenuItem>
                  )}
                  {userInfoWithPermissions.security.showReception && (
                    <MenuItem
                      href={createNavigationLink(Page.RECEPTION_OVERVIEW, {
                        workspaceUUID: getWorkspaceUUID(),
                      })}
                    >
                      Recepce
                    </MenuItem>
                  )}
                  {userInfoWithPermissions.security.showReservation && (
                    <>
                      <MenuItem
                        href={createNavigationLink(Page.RESERVATION_LIST, {
                          workspaceUUID: getWorkspaceUUID(),
                        })}
                      >
                        Rezervace
                      </MenuItem>
                      <MenuItem
                        href={createNavigationLink(Page.RESERVATION_CALENDAR, {
                          workspaceUUID: getWorkspaceUUID(),
                        })}
                      >
                        Kalendar
                      </MenuItem>
                    </>
                  )}
                  {userInfoWithPermissions.security.showReports && (
                    <MenuItem
                      href={createNavigationLink(Page.REPORT_RESOURCEGROUP, {
                        workspaceUUID: getWorkspaceUUID(),
                      })}
                    >
                      Report
                    </MenuItem>
                  )}
                  {userInfoWithPermissions.security.showRestaurant && (
                    <MenuItem
                      href={createNavigationLink(Page.RST_DASHBOARD, {
                        workspaceUUID: getWorkspaceUUID(),
                      })}
                    >
                      Restaurace
                    </MenuItem>
                  )}
                </Menu>
                <Headline>
                  {identity && identity.workspaces && (
                    <DropdownStyle>
                      <Dropdown
                        value={getWorkspaceUUID()}
                        options={identity.workspaces}
                        optionValue={'uuid'}
                        optionLabel={'workspaceName'}
                        onChange={(e) => switchWorkspace(e.value)}
                      />
                    </DropdownStyle>
                  )}
                </Headline>
              </ToolbarContainer>
              {user && (
                <DropdownWrapper>
                  <UserPanel
                    ref={reference}
                    onClick={(e) => {
                      {
                        setOpenMenu(!openMenu);
                      }
                    }}
                  >
                    <UserName>{user.name}</UserName>
                    {/*                    <Avatar src={user.picture} />*/}
                  </UserPanel>
                  <DropdownContent visible={openMenu}>
                    <DropdownInnerWrapper>
                      <DropdownMenuLink onClick={(e) => setAddWorkspace(true)}>
                        <i className={'pi pi-plus-circle'} /> Add new workspace
                      </DropdownMenuLink>
                      <DropdownMenuLink onClick={(e) => navigate(Page.SETTINGS, { workspaceUUID: getWorkspaceUUID() })}>
                        <i className={'pi pi-cog'} /> Settings
                      </DropdownMenuLink>
                      <DropdownMenuLink onClick={(e) => logout()}>
                        <i className={'pi pi-power-off'} /> Logout
                      </DropdownMenuLink>
                      {identity && identity.workspaces && (
                        <>
                          <Hr />
                          {identity.workspaces.map((w, wKey) => {
                            return (
                              <Link href={`/app/${w.uuid}`} key={wKey}>
                                <WorkspaceItem selected={w.uuid == getWorkspaceUUID()}>{w.workspaceName}</WorkspaceItem>
                              </Link>
                            );
                          })}
                        </>
                      )}
                    </DropdownInnerWrapper>
                  </DropdownContent>
                </DropdownWrapper>
              )}
            </>
          )}
        </TopPanelWrapper>
        {showResponsiveMenu && (
          <ResponsiveMenu>
            {userInfoWithPermissions.security.showExpenseModule && (
              <MenuItem
                href={createNavigationLink(Page.EXPENSE_LIST, {
                  applicationParams: {},
                  workspaceUUID: getWorkspaceUUID(),
                })}
              >
                Naklady
              </MenuItem>
            )}
            {userInfoWithPermissions.security.showInvoiceModule && (
              <MenuItem
                href={createNavigationLink(Page.INVOICE_LIST, {
                  applicationParams: {},
                  workspaceUUID: getWorkspaceUUID(),
                })}
              >
                Faktury
              </MenuItem>
            )}
            {userInfoWithPermissions.security.showBankModule && (
              <MenuItem
                href={createNavigationLink(Page.BANKITEMS_LIST, {
                  workspaceUUID: getWorkspaceUUID(),
                })}
              >
                Bankovni pohyby
              </MenuItem>
            )}
            {userInfoWithPermissions.security.showCars && (
              <MenuItem
                href={createNavigationLink(Page.CAR_LIST, {
                  workspaceUUID: getWorkspaceUUID(),
                })}
              >
                Auta
              </MenuItem>
            )}
            {userInfoWithPermissions.security.showRenting && (
              <MenuItem
                href={createNavigationLink(Page.RENTAL_LIST, {
                  workspaceUUID: getWorkspaceUUID(),
                })}
              >
                Pronajmy
              </MenuItem>
            )}
            {userInfoWithPermissions.security.showRoomservice && (
              <MenuItem
                href={createNavigationLink(Page.ROOMSERVICE_OVERVIEW, {
                  workspaceUUID: getWorkspaceUUID(),
                })}
              >
                Uklid
              </MenuItem>
            )}
            {userInfoWithPermissions.security.showReception && (
              <MenuItem
                href={createNavigationLink(Page.RECEPTION_OVERVIEW, {
                  workspaceUUID: getWorkspaceUUID(),
                })}
              >
                Recepce
              </MenuItem>
            )}
            {userInfoWithPermissions.security.showReservation && (
              <>
                <MenuItem
                  href={createNavigationLink(Page.RESERVATION_LIST, {
                    workspaceUUID: getWorkspaceUUID(),
                  })}
                >
                  Rezervace
                </MenuItem>
                <MenuItem
                  href={createNavigationLink(Page.RESERVATION_CALENDAR, {
                    workspaceUUID: getWorkspaceUUID(),
                  })}
                >
                  Kalendar
                </MenuItem>
              </>
            )}
            {userInfoWithPermissions.security.showReports && (
              <MenuItem
                href={createNavigationLink(Page.REPORT_RESOURCEGROUP, {
                  workspaceUUID: getWorkspaceUUID(),
                })}
              >
                Report
              </MenuItem>
            )}
            {userInfoWithPermissions.security.showRestaurant && (
              <MenuItem
                href={createNavigationLink(Page.RST_DASHBOARD, {
                  workspaceUUID: getWorkspaceUUID(),
                })}
              >
                Restaurace
              </MenuItem>
            )}
          </ResponsiveMenu>
        )}
      </MaxWidthWrapper>
      <Submenu>{subMenu}</Submenu>
      <AddWorkspace
        onHide={() => setAddWorkspace(false)}
        visible={addWorkspace}
        onCompleted={(workspaceUUID) => {
          setAddWorkspace(false);
          hardNavigate(Page.DASHBOARD, { workspaceUUID });
        }}
      />
    </>
  );
};

const MenuItem = styled.a`
  display: flex;
  padding: 1rem 1rem;
  color: ${(props) => (props.selected ? '#fedc43' : 'white')};
  cursor: pointer;
  font-size: 1.125rem;
  text-decoration: none;
`;

const ResponsiveMenu = styled.div`
  display: none;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    ${MenuItem} {
      justify-content: center;
    }
  }
`;

const BurgerMenuWrapper = styled.div`
  display: none;
  padding: 1rem;
  @media screen and (max-width: 900px) {
    display: block;
    cursor: pointer;
  }
`;

const WorkspaceItem = styled.div<{ selected: boolean }>`
  padding: 1rem 1rem;
  color: white;
  cursor: pointer;
  opacity: 50%;
  ${(props) =>
    props.selected &&
    css`
      font-weight: 700;
      opacity: 100;
    `}
`;

const Hr = styled.hr`
  width: 90%;
  height: 1px;
  background-color: white;
  opacity: 50%;
  margin: 0.5rem auto;
`;

const DropdownInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const DropdownMenuLink = styled.a`
  display: flex;
  gap: 1rem;
  padding: 1rem 1rem;
  cursor: pointer;
  color: white;
  white-space: nowrap;

  &:hover {
    color: var(--link-active-color);
  }
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.visible === true ? 'flex' : 'none')};
  position: absolute;
  background-color: var(--menu-backround-color);
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
  z-index: 1;
  right: 0;
  top: 0;
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const UserMenu = styled.div`
  position: absolute;
  display: flex;
`;
const DropdownStyle = styled.div`
  .p-dropdown:not(p-disabled).p-focus {
    border: 0px !important;
    box-shadow: none;
  }

  .p-inputwrapper-focus {
    border: 10px solid black;
  }

  .p-inputwrapper-filled {
    background-color: var(--menu-backround-color);
    border: 0px;
  }

  .p-dropdown-label {
    color: white;
  }

  .p-dropdown-trigger-icon {
    color: white;
  }

  @media screen and (max-width: 920px) {
    display: none;
  }
`;

const Logout = styled.a`
  display: flex;
  color: yellow;
  cursor: pointer;
`;

const MaxWidthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--menu-backround-color);
`;

const Submenu = styled.div`
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  margin: 0 auto;
`;
const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

const SecondaryMenuItem = styled.a`
  display: flex;
  padding: 1rem 1rem;
  cursor: pointer;
  font-size: 1.125rem;
  text-decoration: none;
  color: black;
`;

const Menu = styled.div`
  display: flex;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const UserName = styled.div`
  color: #eff0f2;
`;
const UserPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Headline = styled.div`
  padding: 13px;
  color: #eff0f2;
  font-size: large;
`;
const Divider = styled.div`
  width: 1px;
  border-left: 1px solid rgb(101, 103, 110);
  height: 50%;
  margin-left: 10px;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  color: white;
  padding: 10px;
  margin-left: 5px;
`;

const TopPanelWrapper = styled.div`
  width: 100vw;
  display: flex;
  height: 56px;
  background-color: var(--menu-backround-color);
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 20px;
`;

export default TopPanel;
