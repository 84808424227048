import React, { useContext, useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import styled from 'styled-components';
import TopPanel from '../TopPanel/TopPanel';
import LeftMenu from '../LeftMenu/LeftMenu';

interface ComponentProps {
  subMenu?: any;
}

const Layout: React.FC<ComponentProps> = ({ children, subMenu = <></> }) => {
  return (
    <>
      <TopPanel subMenu={subMenu} />
      <LayoutWraper>
        <MainWrapper>
          <ContentWrapper>{children}</ContentWrapper>
        </MainWrapper>
      </LayoutWraper>
    </>
  );
};

const ContentWrapper = styled.div`
  margin: 0px auto;

`;
const MainWrapper = styled.div`
  width: 100%;
  padding: 1rem 1rem;
  margin: 0 auto;
`;

const LayoutWraper = styled.div`
  width: 100%;

  display: flex;
  background-color: white;
`;

export default Layout;
